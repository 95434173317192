<template>
  <a-card>
    <div slot="title">基本信息</div>

    <basis-form ref="basis" />

    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button :loading="loading" type="primary" shape="round" style="width:160px" @click="onSubmit">
          <span>保存</span>
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import events from '@/components/MultiTab/events'
import * as skillApi from '@/api/skill'
import BasisForm from './components/BasisForm'

export default {
  name: 'SkillManagementSkillEdit',
  components: {
    BasisForm
  },
  data() {
    return {
      loading: false
    }
  },
  watch: {
    $route() {
      const {
        $route: { name },
        initData
      } = this

      if (name === 'SkillManagementSkillEdit') {
        initData()
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        },
        $refs: { basis }
      } = this

      skillApi.detail(id).then(res => {
        basis.initData(res.data)
      })
    },
    onSubmit() {
      const {
        $refs: { basis },
        $notification,
        close
      } = this

      basis.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            skillApi
              .update(form)
              .then(res => {
                $notification['success']({ message: '操作成功' })
                close()
              })
              .finally(() => (this.loading = false))
          } catch (error) {
            console.log(error)
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped></style>
